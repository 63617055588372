.table {
  table {
    width: 500px;
  }
  th {
    font-style: normal;
    font-weight: 600;
    padding: 6px;
  }
}
.sections {
  > div {
    margin-right: 1.875rem;
    &:last-child {
      margin-right: 0;
    }
  }
}
