@import "common/scss/_theme";
.container {
  .heading {
    h5 {
      font-family: Barlow;
      font-style: normal;
    }
    .fileName {
      font-family: Open Sans;
      font-style: normal;
      font-weight: normal;
    }
  }
  .formFields {
    > div {
      margin-right: 30px;
      &:last-child {
        margin-right: 0;
      }
    }
    .shippingMethod {
      flex: none;
      > ul {
        list-style-type: none;
        margin: 0;
        padding: 0;
        width: 176px;
      }
      ul > li {
        flex: 1 0 auto;
      }
      ul > li:last-child {
        margin-right: 0;
      }
      input[type="radio"] {
        margin-right: 0.4rem;
        vertical-align: text-top;
      }
    }
    .shipTo {
      flex: 2 0 auto;
      position: relative;
      align-self: flex-end;
      &.error input[type="text"] {
        color: color("ui-dark-red-100");
        border: solid 0.1rem color("ui-dark-red-100");
        background-color: color("red-025");
      }
    }
    .requestedDeliveryDate {
      flex: auto;
      position: relative;
      align-self: flex-end;
    }
    .generateQuoteButton {
      align-self: flex-end;
      flex: none;
      > span {
        padding: 4px;
      }
    }
  }
}
.pointerEvents {
  pointer-events: none;
}
