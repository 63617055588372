.modalheader {
  border-bottom: none;
}
.borderTop {
  border: none !important;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
}

.bottomRounded {
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}
