@import "variables";
@import "custom";
@import "Futura";
@import "~bootstrap/scss/bootstrap";
@import "font-awesome/fontawesome.scss";
@import "font-awesome/solid.scss";
@import "font-awesome/regular.scss";

html,
body {
  font-size: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

//Tooltip override
.bs-popover-top > .arrow::after,
.bs-popover-auto[x-placement^="top"] > .arrow::after {
  border-top-color: theme-color(dark);
}

//Adding button XS for smaller button groups
.btn-xs,
.btn-group-xs > .btn {
  padding: 0.25rem 0.5rem;
  // font-size: 0.75rem;
  line-height: 1;
  border-radius: 3px 0px 0px 3px;
  margin-bottom: 0.5rem;
  border: 1px;
}

//overwriting dropdown button class
.dropdown-item > .btn-group-xs {
  display: block;
  width: 100%;
  padding: 0.25rem 1.5rem;
  clear: both;
  font-weight: 400;
  color: #192d38;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  color: #7f99b2;
  border-color: black;
  border: 1px;
  border-radius: 3rem;
}
