@import "common/scss/_theme";

.heading {
  h6 {
    font-family: Barlow;
  }
  p {
    font-style: normal;
    font-weight: normal;
  }
}

.footer {
  color: color("gray-1-050");
}
