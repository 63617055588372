@import "common/scss/_theme";

.container {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  display: inline-block;
  width: 13px;
  height: 13px;
  border: 2px solid color("gray-2-075");
  border-radius: 50%;
  &:checked {
    border: 4px solid color("ui-bright-green-100");
  }
  &.large {
    width: 18px;
    height: 18px;
    &:checked {
      border: 6px solid color("ui-bright-green-100");
    }
  }
}
