@import "common/scss/_theme";
.heading {
  font-family: Barlow;
  font-style: normal;
}
.sliderValueDisplay {
  background-color: color("gray-5-050");
  border: solid 1px color("gray-4-075");
  border-radius: 2px;
  //box-shadow: 0px 1px 3px color("gray-3-100");
}
.renderTrack {
  height: 30px;
}
.sliderBox {
  height: 5px;
  box-shadow: 0px 1px 3px color("gray-3-100");
  width: 98% !important;
}
.sliderBoxThumb {
  height: 15px;
  width: 15px;
  border-radius: 10px;
  box-shadow: 0px 2px 6px color("gray-3-100");
}
.sliderBoxValue {
  top: 18px;
  color: gray("700");
  font-family: Arial, Helvetica Neue, Helvetica, sans-serif;
}
.sliderBoxPipe {
  height: 5px;
  width: 5px;
}
.sliderLabel {
  color: gray("700");
  word-spacing: 7.6px;
}
