@import "common/scss/_theme";
.uploadArea {
  width: 330px;
}

.templateDownloadBtnArea {
  border: 1px solid $GRAY_4_050;
  border-radius: 5px;
}

.downloadLinkText {
  font-size: 14px;
  line-height: 14px;
  width: 203px;
  padding: 8px !important;
}

.howToLinkText {
  font-size: 12px;
  line-height: 18px;
  text-align: center;
  color: $GRAY_1_075;
  margin: 0px 0px;
}

.lh1 {
  line-height: 1 !important;
}

.maintenanceIcon {
  /* ico_first aid */
  position: absolute;
  left: 0%;
  right: 0.34%;
  top: 40.76%;
  bottom: -39.24%;

  /* Blues/Gerdau Light-Blue 100 */

  background: $LIGHT_BLUE_100;
}

.maintenanceTextTop {
  /* The site is down for maintenance. */
  left: 8.01%;
  right: 8.33%;
  top: 61.34%;
  bottom: 27.32%;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  /* identical to box height */

  color: $GRAY_1_100;
}

.maintenanceTextBottom {
  /* We’ll be up and running soon */

  left: 22.76%;
  right: 23.08%;
  top: 79.9%;
  bottom: 11.86%;

  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 16px;
  text-align: center;

  color: #192d38;
}

.maintenanceArea {
  width: 430px;
}

.templateUpdateHeader {
  color: $UI_DARK_GREEN_100;
}

.templateUpdateMessage {
  color: $GRAY_1_075;
}
