.formContainer {
  .fileOption1 {
    width: 349px;
  }
  .fileOption2 {
    width: 379px;
  }
}

.pointerEvents {
  pointer-events: none;
}
