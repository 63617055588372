@import "common/scss/_theme";

$gray5-75: color("gray-5-075");

.qqtButton {
  line-height: 1;
  &:disabled {
    border: 1px solid color("gray-4-050");
    background-color: $gray5-75 !important;
    color: theme-color("inactive");
    opacity: 1;
    &:hover {
      background-color: $gray5-75 !important;
    }
  }
}
