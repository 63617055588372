@import "common/scss/_theme";
@import "~react-calendar/dist/Calendar";
.calendar-container {
  position: absolute;
  top: 62px;
  z-index: 10;
}
.react-calendar {
  font-family: Open Sans;
  font-size: 0.875rem;
  background-color: color("gray-5-025");
  line-height: 1.1875rem;
  width: auto;
  abbr[title] {
    text-decoration: none;
  }
  button {
    &.react-calendar__tile {
      background-color: color("gray-5-050");
      border-radius: 3px;
      border: 1px solid color("white");
      padding: 8px;
      color: theme-color("primary");
      &.react-calendar__month-view__days__day--neighboringMonth {
        background-color: color("white");
        color: color("gray-2-050");
        &:disabled {
          color: color("gray-2-050");
        }
      }
      &:disabled {
        color: theme-color("primary");
      }
    }

    &.react-calendar__tile--active {
      background-color: color("blue-100");
      color: color("white");
      &:enabled:hover,
      &:enabled:focus {
        background-color: color("blue-100");
      }
    }
    &.react-calendar__tile--now {
      border: 1px solid color("blue-100");
      &:enabled:hover {
        background-color: #e6e6e6;
      }
      &:enabled:focus {
        background-color: color("blue-100");
      }
    }
  }
}
.react-calendar__month-view__days {
  padding: 0 6px 6px 6px;
}
.react-calendar__navigation {
  margin-bottom: 0;
  button {
    font-weight: bold;
    color: color("gray-2-100");
    &:enabled:hover,
    &:enabled:focus {
      background-color: transparent;
    }
    &:disabled {
      background-color: color("gray-5-025");
    }
  }
}

.react-calendar__month-view__weekdays__weekday {
  color: color("gray-2-100");
  font-weight: normal;
}
.react-calendar__month-view__weekdays {
  font-size: 14px;
}
