@import "common/scss/_theme";

.loading {
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: rgba(229, 229, 229, 0.9);
}
.content {
  top: 287px;
  z-index: 1001;
  svg {
    margin-bottom: 0.6rem;
  }
  h4,
  h5 {
    font-family: Barlow;
    font-style: normal;
  }
  h4 {
    line-height: 1.5rem;
    color: color("blue-075");
  }
}
