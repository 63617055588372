@import "common/scss/_theme";

@mixin input-style {
  border-radius: 3px;
  border: 1px solid color("blue-050");
  box-shadow: inset 0px 0px 5px 0px rgba(0, 0, 0, 0.25);
  background-color: gray("100");
  &::placeholder {
    color: color("blue-075");
    font-style: italic;
  }
}

.container {
  @include input-style;
}
