@import "common/scss/_theme";
.container {
  max-width: 440px;
  min-width: 400px;
  .button {
    border-color: color("blue-050");
    color: color("blue-050");
    background-color: inherit;
    font-style: normal;
    font-weight: normal;
    &.active {
      background-color: color("blue-050");
      color: color("white");
    }
  }
}
