@import "common/scss/_theme";

.container {
  position: fixed;
  bottom: 0;
  left: 0;
  height: 60px;

  p {
    color: color("sky-blue-025");
  }
  input[type="text"] {
    height: 22px;
    width: 122px;
    border-width: 0;
    text-align: right;
    font-weight: 700;
  }
}
