$font-family-base: Open Sans;
$spacer: 1rem;
$spacers: ();
$spacers: map-merge(
  (
    0: 0,
    2: (
      $spacer * 0.125,
    ),
    3: (
      $spacer * 0.1875,
    ),
    4: (
      $spacer * 0.25,
    ),
    5: (
      $spacer * 0.3125,
    ),
    6: (
      $spacer * 0.375,
    ),
    7: (
      $spacer * 0.437,
    ),
    8: (
      $spacer * 0.5,
    ),
    10: (
      $spacer * 0.625,
    ),
    12: (
      $spacer * 0.75,
    ),
    14: (
      $spacer * 0.875,
    ),
    16: $spacer,
    18: (
      $spacer * 1.125,
    ),
    20: (
      $spacer * 1.25,
    ),
    22: (
      $spacer * 1.375,
    ),
    24: (
      $spacer * 1.5,
    ),
    26: (
      $spacer * 1.625,
    ),
    28: (
      $spacer * 1.75,
    ),
    30: (
      $spacer * 1.875,
    ),
    32: (
      $spacer * 2,
    ),
    34: (
      $spacer * 2.125,
    ),
    36: (
      $spacer * 2.25,
    ),
    38: (
      $spacer * 2.375,
    ),
    40: (
      $spacer * 2.5,
    ),
    42: (
      $spacer * 2.625,
    ),
    44: (
      $spacer * 2.75,
    ),
    48: (
      $spacer * 3,
    ),
    52: (
      $spacer * 3.25,
    ),
    58: (
      $spacer * 3.625,
    ),
    70: (
      $spacer * 4.375,
    ),
    78: (
      $spacer * 4.875,
    ),
    100: (
      $spacer * 6.25,
    ),
    104: (
      $spacer * 6.5,
    ),
    162: (
      $spacer * 10.125,
    ),
  ),
  $spacers
);

$sizes: (
  95: 95%,
);
//Custom font size classes
@each $size, $length in $spacers {
  .font-size-#{$size} {
    font-size: $length !important;
  }
}
