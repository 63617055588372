@import "common/scss/_theme";
.container {
  min-width: 1116px;
  h2 {
    font-family: Futura;
    font-style: normal;
    font-weight: 500;
    svg {
      vertical-align: baseline;
    }
  }
}
