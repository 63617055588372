@import "common/scss/_theme";
.container {
  h6 {
    font-family: Barlow;
  }
  .openSans {
    font-family: Open Sans;
  }
  .dropBox {
    border: solid 1px color("yellow-075");
    border-radius: 2px;
  }
  .close {
    color: color("white");
    opacity: 1;
  }
  .progressBarSpace {
    border: 1px solid color("gray-4-100") !important;
  }
}
