/* Palladium colors */

/* Standards */
$BLACK: #000;
$WHITE: #fff;

/* Neutrals */
$GRAY_1_100: #192d38;
$GRAY_1_075: #53626a;
$GRAY_1_050: #8c969b;
$GRAY_1_025: #c5cacd;

$GRAY_2_100: #495961;
$GRAY_2_075: #778389;
$GRAY_2_050: #a4acb0;
$GRAY_2_025: #d1d5d7;

$GRAY_3_100: #83929b;
$GRAY_3_075: #a2adb4;
$GRAY_3_050: #c1c8cd;
$GRAY_3_025: #e0e4e6;

$GRAY_4_100: #bfc9d0;
$GRAY_4_075: #cfd7dc;
$GRAY_4_050: #dfe4e7;
$GRAY_4_025: #eff1f3;

$GRAY_5_100: #ecf2f6;
$GRAY_5_075: #f1f5f8;
$GRAY_5_050: #f5f8fa;
$GRAY_5_025: #fafcfd;

/* Blues */
$BLUE_100: #036;
$BLUE_075: #40668c;
$BLUE_050: #7f99b2;
$BLUE_025: #bfccd9;

$LIGHT_BLUE_100: #0578be;
$LIGHT_BLUE_075: #449ace;
$LIGHT_BLUE_050: #82bbde;
$LIGHT_BLUE_025: #c0ddef;

$SKY_BLUE_100: #39c;
$SKY_BLUE_075: #66b3d9;
$SKY_BLUE_050: #99cce5;
$SKY_BLUE_025: #cce5f2;

$UI_LIGHT_BLUE: #f2f8fc;

/* Yellows */
$YELLOW_100: #fc0;
$YELLOW_075: #ffdb4d;
$YELLOW_050: #ffeb99;
$YELLOW_025: #fff5cc;

$GOLDEN_YELLOW_100: #e5a812;
$GOLDEN_YELLOW_075: #ecbe4d;
$GOLDEN_YELLOW_050: #f2d388;
$GOLDEN_YELLOW_025: #f8e9c4;

/* Greens */
$GREEN_100: #99cc68;
$GREEN_075: #b3d98e;
$GREEN_050: #cce5b3;
$GREEN_025: #e0f0d1;

$LIGHT_GREEN_100: #31af19;

$UI_DARK_GREEN_100: #1e6a0f;
$UI_BRIGHT_GREEN_100: #31af19;

/* Reds */
$RED_100: #c74a34;
$RED_075: #d88071;
$RED_050: #eaada2;
$RED_025: #ffe2dc;

$UI_DARK_RED_100: #900;
$UI_BRIGHT_RED_100: #c00;

/* Purples */
$PURPLE_100: #874487;
$PURPLE_075: #a573a5;
$PURPLE_050: #c3a1c3;
$PURPLE_025: #e1d0e1;

/* RGB Shadows */
$BLACK_SHADOW: rgba(0, 0, 0, 0.5);
$BLACK_SHADOW_INPUT: rgba(0, 0, 0, 0.25);
$WHITE_SHADOW: rgba(255, 255, 255, 0.7);

$colors: (
  "black": $BLACK,
  "white": $WHITE,
  "gray-1-100": $GRAY_1_100,
  "gray-1-075": $GRAY_1_075,
  "gray-1-050": $GRAY_1_050,
  "gray-1-025": $GRAY_1_025,
  "gray-2-100": $GRAY_2_100,
  "gray-2-075": $GRAY_2_075,
  "gray-2-050": $GRAY_2_050,
  "gray-2-025": $GRAY_2_025,
  "gray-3-100": $GRAY_3_100,
  "gray-3-075": $GRAY_3_075,
  "gray-3-050": $GRAY_3_050,
  "gray-3-025": $GRAY_3_025,
  "gray-4-100": $GRAY_4_100,
  "gray-4-075": $GRAY_4_075,
  "gray-4-050": $GRAY_4_050,
  "gray-4-025": $GRAY_4_025,
  "gray-5-100": $GRAY_5_100,
  "gray-5-075": $GRAY_5_075,
  "gray-5-050": $GRAY_5_050,
  "gray-5-025": $GRAY_5_025,
  "blue-100": $BLUE_100,
  "blue-075": $BLUE_075,
  "blue-050": $BLUE_050,
  "blue-025": $BLUE_025,
  "light-blue-100": $LIGHT_BLUE_100,
  "light-blue-075": $LIGHT_BLUE_075,
  "light-blue-050": $LIGHT_BLUE_050,
  "light-blue-025": $LIGHT_BLUE_025,
  "sky-blue-100": $SKY_BLUE_100,
  "sky-blue-075": $SKY_BLUE_075,
  "sky-blue-050": $SKY_BLUE_050,
  "sky-blue-025": $SKY_BLUE_025,
  "ui-light-blue": $UI_LIGHT_BLUE,
  "yellow-100": $YELLOW_100,
  "yellow-075": $YELLOW_075,
  "yellow-050": $YELLOW_050,
  "yellow-025": $YELLOW_025,
  "golden-yellow-100": $GOLDEN_YELLOW_100,
  "golden-yellow-075": $GOLDEN_YELLOW_075,
  "golden-yellow-050": $GOLDEN_YELLOW_050,
  "golden-yellow-025": $GOLDEN_YELLOW_025,
  "green-100": $GREEN_100,
  "green-075": $GREEN_075,
  "green-050": $GREEN_050,
  "green-025": $GREEN_025,
  "light-green-100": $LIGHT_GREEN_100,
  "ui-dark-green-100": $UI_DARK_GREEN_100,
  "ui-bright-green-100": $UI_BRIGHT_GREEN_100,
  "red-100": $RED_100,
  "red-075": $RED_075,
  "red-050": $RED_050,
  "red-025": $RED_025,
  "ui-dark-red-100": $UI_DARK_RED_100,
  "ui-bright-red-100": $UI_BRIGHT_RED_100,
  "purple-100": $PURPLE_100,
  "purple-075": $PURPLE_075,
  "purple-050": $PURPLE_050,
  "purple-025": $PURPLE_025,
  "black-shadow": $BLACK_SHADOW,
  "white-shadow": $WHITE_SHADOW,
);

//Theme color subset
$primary: $LIGHT_BLUE_100;
$secondary: $BLUE_100;
$tertiary: $BLUE_050;
$success: $GREEN_025;
$success-dark: $GREEN_100;
$sucess-highlight: $UI_BRIGHT_GREEN_100;
$info: $UI_LIGHT_BLUE;
$warning: $YELLOW_025;
$warning-dark: $YELLOW_100;
$danger: $RED_025;
$danger-highlight: $UI_BRIGHT_RED_100;
$light: $GRAY_1_075;
$dark: $GRAY_1_100;
$inactive: $GRAY_4_100;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "tertiary": $tertiary,
  "success": $success,
  "success-dark": $success-dark,
  "success-highlight": $sucess-highlight,
  "info": $info,
  "warning": $warning,
  "warning-dark": $warning-dark,
  "danger": $danger,
  "danger-highlight": $danger-highlight,
  "light": $light,
  "dark": $dark,
  "inactive": $inactive,
);

$black: $BLACK;
$gray-900: $GRAY_1_100;
$gray-800: $GRAY_1_075;
$gray-700: $GRAY_2_075;
$gray-600: $GRAY_3_075;
$gray-500: $GRAY_1_025;
$gray-400: $GRAY_2_025;
$gray-300: $GRAY_3_025;
$gray-200: $GRAY_5_100;
$gray-100: $GRAY_5_025;
$white: $WHITE;

$grays: (
  "100": $gray-100,
  "200": $gray-200,
  "300": $gray-300,
  "400": $gray-400,
  "500": $gray-500,
  "600": $gray-600,
  "700": $gray-700,
  "800": $gray-800,
  "900": $gray-900,
);

// Retrieve color Sass maps
@function color($key: "blue") {
  @return map-get($colors, $key);
}

@function theme-color($key: "primary") {
  @return map-get($theme-colors, $key);
}

@function gray($key: "100") {
  @return map-get($grays, $key);
}
