@import "common/scss/_theme";

.borderTop {
  border-top: 1px solid color("red-075");
  font-weight: 400;
  color: color("gray-1-075");
}
.modalTitle {
  padding-left: 145px !important;
}
.modalHeader {
  background-color: color("red-100") !important;
}
.modalFooter {
  justify-content: center !important;
}
