@import "common/scss/_theme";
.container {
  h5,
  h6 {
    font-family: Barlow;
    font-style: normal;
  }

  .sawPrefs {
    width: 320px;
  }
  .yardPrefs {
    width: 326px;
  }
  .checkBox {
    line-height: 1.2;
  }
  .disclaimer {
    width: 362px;
    border-radius: 5px;
    font-weight: 400;
    border: 1px solid color("gray-4-075");
    ul > li {
      margin-top: 5px;
    }
  }
  .parenthesesStyle {
    color: gray("700");
    font-weight: 400;
  }
}
