@import "common/scss/_theme";

.searchForm {
  input[type="text"] {
    width: 305px;
    &.inputError {
      border: 1px solid color("ui-dark-red-100") !important;
      background-color: theme-color("danger") !important;
    }
  }
  .search {
    .closeIcon {
      left: -16px;
      cursor: pointer;
      fill: color("blue-050");
    }
  }
  .error {
    fill: color("ui-dark-red-100") !important;
  }
  .notFoundError {
    color: color("ui-dark-red-100");
  }
}
