@import "common/scss/_theme";

.qqtNav {
  width: 100%;
  height: 76px;
  border-bottom: solid theme-color("warning-dark") 5px;
  top: 0;
  z-index: 10;
  font-family: Futura;
}
.qqtLogo {
  width: 124px;
  height: 49px;
}
