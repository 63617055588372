@import "common/scss/_theme";

.container {
  .col {
    width: 800px;
  }
  video {
    width: 800px;
    height: 450px;
  }
  h4 {
    font-family: Barlow;
    scroll-margin-top: 4.75rem;
  }
  ul {
    > li {
      background-image: url(../../assets/redesign_icons/arrow_down.svg);
      background-position: left center;
      background-repeat: no-repeat;
      padding-left: 0.875rem;
      line-height: 2.1;
      a {
        text-decoration: none;
      }
    }
  }
  .emailCard {
    background-color: color("gray-4-025");
  }
  img {
    margin: -30px 0px 0px -30px;
  }
}
